<template>
  <v-dialog persistent v-model="OpeningDialog" max-width="500px">
    <v-card outlined class="statistics-horizontal">
      <v-card-title> Please enter the customer number! </v-card-title>
      <v-form ref="registerForm" @submit.prevent="closeDialog">
        <v-text-field
          autofocus
          id="phoneNumber"
          class="pb-5 px-5 flex-grow-0 msg-input"
          v-model="phoneNumber"
          :rules="[required, lengthValidator(phoneNumber, 10), integerValidator]"
          label="Phone Number"
        ></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined :to="{ name: 'AgentHome' }"> Cancel </v-btn>
          <v-btn color="success" type="submit"> OK </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { integerValidator, lengthValidator, required } from '@core/utils/validation'
import { onMounted, ref } from '@vue/composition-api'
export default {
  name: 'EnterDialog',
  model: {
    prop: 'OpeningDialog',
    event: 'update:opening-dialog',
  },
  props: {
    OpeningDialog: {
      type: Boolean,
      required: true,
    },
    setPhoneNumber: {
      type: Function,
    },
  },
  setup(props, { emit }) {
    onMounted(() => {
      document.getElementById('phoneNumber').focus()
    })
    const phoneNumber = ref('')
    const registerForm = ref(null)
    const closeDialog = () => {
      const isFormValid = registerForm.value.validate()
      if (isFormValid == true) {
        props.setPhoneNumber(phoneNumber.value)
        emit('update:opening-dialog', false)
      } else {

      }
    }
    return {
      phoneNumber,
      closeDialog,
      registerForm,
      integerValidator,
      required,
      lengthValidator,
    }
  },
}
</script>
