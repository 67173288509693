import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.OpeningDialog),callback:function ($$v) {_vm.OpeningDialog=$$v},expression:"OpeningDialog"}},[_c(VCard,{staticClass:"statistics-horizontal",attrs:{"outlined":""}},[_c(VCardTitle,[_vm._v(" Please enter the customer number! ")]),_c(VForm,{ref:"registerForm",on:{"submit":function($event){$event.preventDefault();return _vm.closeDialog.apply(null, arguments)}}},[_c(VTextField,{staticClass:"pb-5 px-5 flex-grow-0 msg-input",attrs:{"autofocus":"","id":"phoneNumber","rules":[_vm.required, _vm.lengthValidator(_vm.phoneNumber, 10), _vm.integerValidator],"label":"Phone Number"},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","outlined":"","to":{ name: 'AgentHome' }}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"color":"success","type":"submit"}},[_vm._v(" OK ")]),_c(VSpacer)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }